import React from "react";
import Maintenance from "../util/Maintenance";

function Contacts() {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <div className="h1">Contacts</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ml-2">
          @alexandra_puzza
        </div>
      </div>
      <Maintenance></Maintenance>
    </>
  );
}

export default Contacts;
