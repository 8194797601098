import React from 'react';
import Maintenance from '../util/Maintenance';

function Cart() {
  return (
    <>
    <div className="row">
        <div className="col-12 text-center">
          <div className="h1">Cart</div>
        </div>
      </div>
    <Maintenance>

    </Maintenance>
    </>
  );
}

export default Cart;