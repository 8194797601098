import React from 'react';
import Maintenance from '../util/Maintenance';

function Shop() {
  return (
    <>
    <div className="row">
        <div className="col-12 text-center">
          <div className="h1">Shop</div>
        </div>
      </div>
    <Maintenance>

    </Maintenance>
    </>
  );
}

export default Shop;