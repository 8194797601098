import "./App.css";
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import "./index.css";
import Home from "./components/home";
import Cart from "./components/cart";
import Shop from "./components/shop";
import Menu from "./layout/menu";
import Contacts from "./components/contacts";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"


export default function App() {
  return (
    <div>
     
      
      {/* FONT AWESOME */}
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" />

      {/* menu inizio */}
      <Menu></Menu>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </Router>
    </div>
  );
}
