import React from "react";

function Maintenance() {
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <div className="h1">Work in progress</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
         <i className="fas fa-spinner fa-4x fa-pulse"></i>
        </div>
      </div>
    </>
  );
}

export default Maintenance;
