import React from "react";

function Menu() {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <a class="navbar-brand" href="/#home">
          Nobelium
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" href="/#home">
                Home <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#shop">
                Shop
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#contacts">
                Contacts
              </a>
            </li>
          </ul>
          <ul class="navbar-nav navbar-right">
            <li></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="/#cart" role="button" data-toggle="dropdown" aria-expanded="false">
              Cart <i class="ml-2 fa-solid fa-cart-shopping"></i>
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="/#cart">
                  Action
                </a>
                <a class="dropdown-item" href="/#cart">
                  Another action
                </a>
                
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Menu;
